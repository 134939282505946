<template>
    <div class="password_change">
        <div class="form_warpper">
            <div class="form_box">
                <el-form ref="formRef" :model="form" :rules="rules" label-width="120px" size="large">
                    <el-form-item label="原始密码" prop="oldPassword">
                        <el-input type="password" :maxlength="60" v-model.trim="form.oldPassword" placeholder="请输入您的原始密码" />
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input type="password" :maxlength="60" v-model="form.newPassword" placeholder="请输入您的新密码" />
                    </el-form-item>
                    <el-form-item label="重复新密码" prop="validatePassword">
                        <el-input type="password" :maxlength="60" v-model="form.validatePassword" placeholder="请再次输入新密码" />
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="submitForm(formRef)">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { apiUserReset } from '@/request/api';
import withLoading from '@/utils/loading';
import { ElMessage } from 'element-plus';
import outLoginHook from '@/utils/outLoginHook';

const formRef = ref();

const validatePass = (_, value, callback) => {
    if (value === '') {
        callback(new Error('请再次输入新密码'))
    } else if (value !== form.newPassword) {
        callback(new Error("两次输入的新密码不一致！"))
    } else {
        callback()
    }
}

const form = reactive({
    oldPassword: '',
    newPassword: '',
    validatePassword: ''
});
const rules = reactive({
    oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
    newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
    validatePassword: [{ validator: validatePass, trigger: 'blur' }],
})

// 退出登录
const { outLoginFun } = outLoginHook();

// 提交验证
const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            resetPass();
        }
    })
}

// 修改密码请求
async function resetPass() {
    const params = { newPassword: form.newPassword, oldPassword: form.oldPassword }
    const { code } = await withLoading(apiUserReset)(params);
    if (code !== '0') return;
    ElMessage.success('修改成功,请重新登录');
    outLoginFun();
}
</script>

<style lang="scss" scoped>
.password_change {
    height: calc(100vh - var(--home-header-height));
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 33px 60px;

    .form_warpper {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .form_box {
            .el-input {
                width: 400px;
            }

            .el-button {
                width: 400px;
                border-radius: 8px;
                background: linear-gradient(-63.43deg, rgba(0, 149, 212, 1) 0%, rgba(0, 92, 169, 1) 100%);
                color: #fff;
                font-size: 18px;
                letter-spacing: 5px;
            }
        }
    }
}
</style>